.error-field {
  position: absolute;
  top: 2px;
  right: 5px;
  display: inline-block;
  font-size: 10px;
  color: red;
  opacity: 0;
  opacity: 0;
  transition: all 0.7s;
}

.error-field--visible {
  opacity: 1;
}
