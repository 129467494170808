.submit-btn {
  margin-top: 15px;
  padding: 5px;
  border: none;
  background-color: inherit;
  font-size: 16px;
  font-family: 'Open Sans', 'Helvetica', sans-serif;
  line-height: 1;
  color: black;
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
}

.submit-btn:active {
  color: blue;
  opacity: 0.3;
}

.submit-btn:disabled {
  color: grey;
}

@media (min-width: 1025px) {
  .submit-btn:hover {
    color: blue;
  }

  .submit-btn:disabled:hover {
    color: grey;
  }
}
