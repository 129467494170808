.footer {

}

.footer__social,
.footer__subscribe,
.footer__fok {
  position: fixed;
  z-index: 10;
  bottom: 10px;
}

.footer__social {
  left: 10px;
}

.footer__social a {
  opacity: 1;
  transition: all 0.3s;
}

.footer__social a:active {
  opacity: 0.5;
}

.footer__social svg {
  width: 25px;
}

.footer__subscribe {
  left: 45px;
  bottom: 16px;
}

.footer__subscribe button {
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 14px;
  line-height: 1;
  font-weight: 300;
  color: white;
  background-color: transparent;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;
}

.footer__subscribe button:active {
  opacity: 0.5;
}

.footer__fok {
  right: 10px;
  bottom: 12px;
}

.footer__fok a {
  opacity: 1;
  transition: all 0.3s;
}

.footer__fok a:active {
  opacity: 0.5;
}

.footer__fok svg {
  width: 135px;
}

@media (min-width: 768px) {
  .footer__social {
    left: 15px;
  }

  .footer__subscribe {
    left: 55px;
  }

  .footer__fok {
    right: 15px;
  }

  .footer__fok svg {
    width: 200px;
  }
}
