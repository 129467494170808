.header {

}

.header__message {
  position: fixed;
  z-index: 10;
  top: 8px;
  /* right: 0; */
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 1.4;
  font-weight: 300;
}

.header__sound {
  position: fixed;
  z-index: 10;
  right: 10px;
  top: 7px;
}

.header__sound button {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 0;
  outline: 0;
  opacity: 1;
  transition: all 0.3s;
}

.header__sound button:active {
  opacity: 0.5;
}

.header__sound svg {
  height: 15px;
}

@media (min-width: 768px) {
  .header__message {
    font-size: 14px;
  }

  .header__sound {
    right: 15px;
  }

  .header__sound svg {
    height: 20px;
  }
}

@media (min-width: 1025px) {

}
