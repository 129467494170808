.video-bg {

}

.video-bg__overlay,
.video-bg__video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.video-bg__overlay {
  width: 150%;
  height: 150%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.7;
}

.video-bg__video {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 8;
}

@media (max-width: 767px) and (orientation:portrait) {
  .video-bg__video {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 767px) and (orientation:landscape) {

}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {

}
