.input-text {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: 2px solid grey;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s;
}

.input-text:focus {
  border-color: black;
}

.input-text--error {
  border-color: red !important;
}
