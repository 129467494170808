.subscribe-window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  animation: 0.5s appear;
}

.subscribe-window__form {
  position: relative;
  z-index: 210;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  font-size: 20px;
  font-family: 'Open Sans', 'Helvetica', sans-serif;
  line-height: 1.4;
  background-color: white;
  color: black;
}

.subscribe-window div:last-child {
  margin-bottom: 0;
}

.subscribe-window label {

}

.subscribe-window__submit {
  margin: 0;
  text-align: right;
}

@media (min-width: 768px) {
  .subscribe-window__form {
    width: 450px;
  }
}

@media (min-width: 1025px) {

}


@keyframes appear {
  from {
    opacity: 0
  }
}
